import React, { useState, useEffect } from 'react'
import axios from 'axios'
const APIURL2 = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV2}` : `${process.env.REACT_APP_DB_ENV_TEST2}`

export default function Test(x) {
  const onClick2Btn =async()=>{
    try {
      const { data: res } = await axios.get(`${APIURL2}/stock`)
      console.log(res)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <><button onClick={onClick2Btn}>Test if I can get data from python</button>
      {/* <button onClick={()=>onClick2APICar()}>Car API</button><br/>*/}
    </>
  )
}