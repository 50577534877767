import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import './style.css'
import axios from 'axios'
import { FiLogOut } from 'react-icons/fi'
import { AiFillGoogleCircle, AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'
import { MdEdit, MdEditOff } from 'react-icons/md'
import { renderToStaticMarkup } from 'react-dom/server'
import { IoIosClose } from 'react-icons/io'
import { IoTimeSharp, IoCloseCircleSharp } from 'react-icons/io5'
import { GiConsoleController } from 'react-icons/gi'
const svgStringMdEdit = encodeURIComponent(renderToStaticMarkup(<MdEdit />))
const svgStringIoIosClose = encodeURIComponent(renderToStaticMarkup(<IoIosClose />))
const APIURL2 = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV2}` : `${process.env.REACT_APP_DB_ENV_TEST2}`
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}
// **mongodb admin roles**
const ROLE = {
  ADMIN: 'admin',
  BASIC: 'basic',
}
export const LoginWithDifferentDB = () => {
  const [modal, setModal] = useState(false)
  const [loginData3, setLoginData3] = useState(null)
  const token = localStorage.getItem('token')
  const [selectedTable, setSelectedTable] = useState('json')
  const loginupdateData = (x) => {setLoginData3(x)}
  const optionTabs = [
    {
      ttl: 'json',
      table: <>{token ? <Logined loginData4={loginData3} /> : <><Login loginData1={loginupdateData} /></>}</>
    }
  ]
  return (
    <div className='sample-container-002'>
      <h4 className='sample-title'>Account Manage/Token Expirery Timer/Login&Logout/Admin Role</h4>
      <div className='tag-container'>
        <span className='tech-tag'>React</span><span className='tech-tag'>Python</span>
        <span className='tech-tag'>Mail: STMPD</span>
        <span className='tech-tag'>Secured password: bcrypt</span>
        <span className='tech-tag'>Token: jwt</span>
      </div>
      <div className='sample-contents-container-002'>
        <div className='table-container'>
          {!modal?<div  onClick={()=>setModal(!modal)}><u>Use test account to login?</u></div>:<div >
                <div className='result-data-container login-display-box'>
                   <div className='flex'><div className='flex'><p><b>Normal User Test Account</b><br/>Email: normal@gmail.com<br/>Password: 1</p>
                <p><b>Admin User Test Account</b><br/>Email: admin@gmail.com<br/>Password: 1</p></div><div>
            <IoIosClose style={{fontSize:'20px'}} onClick={()=>setModal(!modal)}/>
                </div></div></div>              
          </div>}
        </div>
        <div className='sample-input-container'>
            <div className='login-tab mb-4'>
              {optionTabs.map((x, i) => (<button
                key={i}
                onClick={() => setSelectedTable(x.ttl)}
                className={selectedTable === x.ttl ? 'login-tab-item-active' : undefined}
                style={{ background: '#fff', padding: '0 4px', fontSize: '12px', color: 'black', position: 'relative' }}>{x.ttl}</button>
              ))}
            </div>
            <div className='login-controller-box'>
              {optionTabs.map((x, i) => x.ttl === selectedTable && <div key={i}>{x.table}</div>)}
            </div>
        </div>
      </div>
    </div>
  )
}

const SectionTitle = ({ title }) => {
  return (
    <h2 className='section-title'>{title}</h2>
  )
}
const Login = ({ loginData1 }) => {
  const [msg, setMsg] = useState('')
  const [subMsg1, setSubMsg1] = useState('')
  const [subMsg2, setSubMsg2] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', role:'', iatstring:'', expstring:'',iat:'', exp:'', users:[{id:'',firstname:'',lastname:'',email:'',pwd:'',role:''}]})
  const [encryption, setEncryption] = useState({ id: '', encryption: '', pwd: '' })
  const [justLogined, setJustLogined] = useState(false)
  const [isExpired, setIsExpired] = useState(true)
  const [page, setPage] = useState('Login')
  const onChangeInput = ({ currentTarget: input }) => { setData({ ...data, [input.name]: input.value }) }
  //one time passcode
  const onChange2Input = ({ currentTarget: input }) => { setUpdateData({ ...updateData, [input.name]: input.value }) }
  //set new pwd
  const onChange3Input = ({ currentTarget: input }) => { setData3({ ...data3, id: updateData.id, [input.name]: input.value }) }
  //set pwd
  const [updateData, setUpdateData] = useState({
    id: '',
    token: ''
  })
  const [err2, setErr2] = useState('')
  const [data3, setData3] = useState({
    id: '',
    pwd: '',
    pwd2: ''
  })
  const onClick2CloseMsg = async (e) => {
    e.preventDefault()
    setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onClick2Login = async (e) => {
    e.preventDefault()
    try {
      const { data: res } = await axios.post(`${APIURL2}/login`, data)
      if(res.errmsg!=undefined){
        setErrMsg(res.errmsg)
      }else{
        localStorage.setItem('token', res.tokenInfo.token)
        loginData1({
          id:res.user.id,
          username:res.user.username,
          email:data.email,
          pwd:data.pwd,
          role:res.user.role,
          msg:res.msg,
          iatstring:new Date(Number(res.tokenInfo.iat)*1000).toString(),
          expstring:new Date(Number(res.tokenInfo.exp)*1000).toString(),
          iat:Number(res.tokenInfo.iat),
          exp:Number(res.tokenInfo.exp),
          justLogined:'true',
          users:res.users
        })
        setJustLogined(true)
        setMsg(res.msg)
        const remove = setTimeout(() => {
          setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
      }
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
    setErr('')
  }  
  const onClick2Register = async(e) => {
    e.preventDefault()
    try {
      const { data: res } = await axios.post(`${APIURL2}/register`, { ...data, ['id']: Math.floor(Math.random()*1000).toString(),['role']:'normal'})
      setData({ email: '', pwd: '' })
      setErr('')
      setPage('Login')
      setMsg(res.msg)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErr(err.response.data.msg)
      }
    }
  }
  //sned onetime passcode
  const onClick2SendOTP = async (x) => {
    setErr('')
    try{
      const { data: res } = await axios.post(`${APIURL2}/otp/send`,{email:x,otp:Math.floor(Math.random()*10000).toString()})
      setSubMsg1(res.msg)
    }catch(err){console.log(err)}
  }
  const onClick2LoginWithOTP = async (e) => {
    e.preventDefault()
    try{
      const { data: res } = await axios.post(`${APIURL2}/otp/login`,updateData)
      if(res.msg!='You are logined.'){
        setSubMsg2(res.msg)
      }else{
        localStorage.setItem('token', res.tokenInfo.token)
        loginData1({
          id:res.user.id,
          username:res.user.username,
          email:data.email,
          pwd:data.pwd,
          role:res.user.role,
          msg:res.msg,
          iatstring:new Date(Number(res.tokenInfo.iat)*1000).toString(),
          expstring:new Date(Number(res.tokenInfo.exp)*1000).toString(),
          iat:Number(res.tokenInfo.iat),
          exp:Number(res.tokenInfo.exp),
          justLogined:'true',
          users:res.users
        })
        setJustLogined(true)
        setMsg(res.msg)
        const remove = setTimeout(() => {
          setMsg('')
        }, 3000)
        return () => clearTimeout(remove)
      }
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
  }
  const onSubmit2NewPwd = async (e) => {
    e.preventDefault()
    console.log('data3', data3)
    
  }
  const onClick2toLoginPage = async (e) => {
    e.preventDefault()
    setData({ email: '', pwd: '' })
    setErr('')
    setPage('Login')
  }
  const onClick2toRegisterPage = async (e) => {
    e.preventDefault()
    setData({ email: '', pwd: '' })
    setErr('')
    setPage('Create Account')
  }
  const onClick2toOnetimePasscodeLoginPage = async (e) => {
    setMsg('')
    e.preventDefault()
    setPage('Onetime Passcode Login')
  }
  const optionTabs = [
    {
      ttl: 'Login',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>Login</div>
        <div className='login-input-container'>
            <div className='text-left'>
              <label>Account Email</label><br/>
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Password</label><br/>
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button className='login-btn' onClick={onClick2Login} disabled={!(data.email && data.pwd)}
            >Login</button>
          <button onClick={onClick2toOnetimePasscodeLoginPage} className='link-btn'>One time passcode login</button>
          <button onClick={onClick2toRegisterPage} className='link-btn'>Create your account</button>
        </div>
      </div></>
    },
    {
      ttl: 'Create Account',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
          {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
        </div>
        <div className='login-nav'>Register</div>
        <div className='login-input-container'>
            <div className='text-left'>
              <label>Username</label><br/>
              <input className='create-new-account-input' type='text' name='username' value={data.username} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Email Address</label><br />
              <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
            </div>
            <div className='text-left'>
              <label>Set Password</label><br />
              <input className='create-new-account-input' type='text' name='pwd' value={data.pwd} onChange={onChangeInput} />
            </div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
              className='register-btn'
              type='button'
              onClick={onClick2Register}
              disabled={!(data.username && data.email && data.pwd)}
            >Register</button>
          <button onClick={onClick2toLoginPage} className='link-btn'>Already have account? Back to Login </button>
        </div>
      </div></>
    },
    {
      ttl: 'Onetime Passcode Login',
      table: <><div className='login-container'>
        <div className='notification-container'>
          {/* {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>} */}
          {/* {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>} */}
        </div>
        <div className='login-nav'>One-time Passcode Login</div>
        {isExpired ? <>
          <div className='login-input-container'>
              <div className='text-left'>
                <label>Account Email</label><br/>
                <input className='create-new-account-input' type='email' name='email' value={data.email} onChange={onChangeInput} />
              </div>
              {subMsg1 && <div className='account-sub-msg'>{subMsg1}</div>}
              {err && <div className='create-new-account-err'>{err}</div>}
              <button onClick={()=>onClick2SendOTP(data.email)} className='login-btn' disabled={!(data.email)}>Send</button>
            <br />
            <div className='text-right' >
              <div>
                <label>Account Email</label>
                <input className='create-new-account-input' type='email' name='email' value={updateData.email} onChange={onChange2Input} />
              </div>
              <div>
                <label>One-time Passcode</label>
                <input className='create-new-account-input' type='text' name='otp' value={updateData.otp} onChange={onChange2Input} />
              </div>
              {subMsg2 && <div className='account-sub-msg'>{subMsg2}</div>}
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn'
                disabled={!(updateData.email && updateData.otp)} onClick={onClick2LoginWithOTP}
              >One-time Passcode Login</button></div>
            <button onClick={onClick2toLoginPage} className='link-btn'>Go Back</button>
            <button onClick={onClick2toRegisterPage} className='link-btn'>Create your account</button>
          </div>
        </> :
          <div className='login-input-container'>
            <form onSubmit={onSubmit2NewPwd}>
              <div className='login-input-ttl'>Please set your new password</div>
              <div className='login-input-txt'>
                <label>Account Id</label>
                {updateData.id}
              </div>
              <div>
                <label>Password</label>
                <input className='create-new-account-input' type='text' name='pwd' value={data3.pwd} onChange={onChange3Input} />
              </div>
              <div>
                <label>Confirm Password</label>
                <input className='create-new-account-input' type='text' name='pwd2' value={data3.pwd2} onChange={onChange3Input} />
              </div>
              {(data3.pwd2.length !== 0) && data3.pwd !== data3.pwd2 && <div className='create-new-account-err'>Please input same password</div>}
              {err && <div className='create-new-account-err'>{err}</div>}
              <button className='login-btn' disabled={!(data3.pwd && data3.pwd2)}>Send</button></form>
            <button className='link-btn' onClick={onClick2ResetPwd}>Go Back</button>
            <button className='link-btn' onClick={onClick2Register}>Create your account</button>
          </div>}
      </div>
      </>
    }
  ]
  return (
    <>
      {page === 'Login' ? <>{optionTabs[0].table}</> : <>{page === 'Create Account' ? <>{optionTabs[1].table}</> : <>{page === 'Onetime Passcode Login' ? <>{optionTabs[2].table}</> : <>{optionTabs[3].table}</>}</>}</>}
    </>
  )
}
const Logined = (props) => {
  const [msg, setMsg] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [err, setErr] = useState('')
  const [data, setData] = useState({username:'', email: '', pwd:'', role:'', iatstring:'', expstring:'',iat:'', exp:'', users:[{id:'',username:'',email:'',pwd:'',role:''}]})
  const [updateData, setUpdateData] = useState({username:'', email: '', pwd:'',role:''})
  const [selectedRole, setSelectedRole] = useState('')
  const [justLogined, setJustLogined] = useState(false)
  const [tokenChecked, setTokenChecked]=useState(false)
  const [timer, setTimer] = useState('')
  const [isExpired, setIsExpired] = useState(true)
  const [updateUserId, setUpdateUserId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [edit2, setEdit2] = useState(false)
  const token = localStorage.getItem('token')
  //User data
  const Ref = useRef(null)
  useEffect(() => {
    // when user clicked login
    if (props.loginData4 !== null) {
      //get tokenInfo, user, users
      const handleSubmit = async (e) => {
        if(justLogined === false){
          setMsg(props.loginData4.msg)
          setData(props.loginData4)
          await setData(props.loginData4)
          setJustLogined(true)
        }
        function expiredMsg() {
          setMsg('Token expired. Please login again')
          localStorage.removeItem('token')
          setTimeout(() => {
            window.location.reload()
          }, 6000)
        }
        setTimeout(expiredMsg, ((props.loginData4.exp - props.loginData4.iat)*1000))
        Ref.current = setInterval(() => {
        let x = new Date()
        const y = props.loginData4.exp - Date.parse(new Date())/1000
        if (y >= 0) {
          setTimer((Math.floor((y / 60 / 60 / 24)) > 1 ? Math.floor((y / 60 / 60 / 24)) + 'days ' :
          Math.floor((y / 60 / 60 / 24)) + 'day ') + (Math.floor((y / 60 / 60) % 24) > 9 ? Math.floor((y / 60 / 60) % 24) :
          '0' + Math.floor((y / 60 / 60) % 24)) +':' + (Math.floor((y / 60) % 60) > 9? Math.floor((y / 60) % 60):
          '0' + Math.floor((y / 60) % 60)) +':' +(Math.floor((y) % 60) > 9 ? Math.floor((y) % 60) :
          '0' + Math.floor((y) % 60)))
        }
        }, 1000)
      }
      setTimeout(handleSubmit, 5)
    }
    // when user refleshed page
    if(props.loginData4 === null && tokenChecked === false){
      const handleSubmit = async (e) => {
      try {
        const {data:res} = await axios.post(`${APIURL2}/tokencheck`, {token:token})
        setTokenChecked(true)
      if(res.msg !==undefined){
        setMsg(res.msg)
        const remove = setTimeout(() => {
        setMsg('')
        localStorage.removeItem('token')
        window.location.reload()
        }, 5000)
        remove()
      }else{
      await setData({
        id:res.user.id,
        username:res.user.username,
        email:res.user.email,
        pwd:res.user.pwd,
        role:res.user.role,
        msg:res.msg,
        iatstring:new Date(Number(res.tokenInfo.iat)*1000).toString(),
        expstring:new Date(Number(res.tokenInfo.exp)*1000).toString(),
        iat:Number(res.tokenInfo.iat),
        exp:Number(res.tokenInfo.exp),
        justLogined:'true',
        users:res.users
      })
      const expireTime =(Number(res.tokenInfo.exp) - Number(res.tokenInfo.iat))*1000
      function expiredMsg() {
      setMsg('Token expired. Please login again')
      setTimeout(() => {
      localStorage.removeItem('token')
      window.location.reload()
      }, 6000)
      }
      setTimeout(expiredMsg,expireTime)
      Ref.current = setInterval(() => {
      let x = new Date()
      //find time difference 10digit - 10digit (second)
      const y = (Number(res.tokenInfo.exp) - (Date.parse(new Date())/1000))
      if (y >= 0) {
      setTimer((Math.floor((y / 60 / 60)) > 1 ? Math.floor((y / 60 / 60 / 24 )) + 'days ' : Math.floor((y / 60 / 60 / 24)) + 'day ') + (Math.floor((y / 60 / 60) % 24) > 9 ? Math.floor((y / 60 / 60) % 24) : '0' + Math.floor((y / 60 / 60) % 24)) +':' + (Math.floor((y / 60) % 60) > 9? Math.floor((y / 60) % 60): '0' + Math.floor((y / 60) % 60)) +':' +(Math.floor((y) % 60) > 9 ? Math.floor((y) % 60) : '0' + Math.floor((y) % 60)))
      }
      }, 1000)
      }
      } catch (err) {
      console.error("Error")
      }
    }
    setTimeout(handleSubmit, 5)
    }
  }, [data])
  const onClick2CloseMsg = async (e) => {
  e.preventDefault()
  setMsg('')
  }
  const onClick2CloseErrMsg = async (e) => {
    e.preventDefault()
    setErrMsg('')
  }
  const onChange2Input = ({ currentTarget: input }) => {
    setUpdateData({ ...updateData, [input.name]: input.value })
  }
  const onChange2Select = (x) => {
    setUpdateData({ ...updateData, ['role']: x })
  }
  const onClick2Logout = (e) => {
    e.preventDefault()
    localStorage.removeItem('token')
    setMsg('See you soon!');
    window.location.reload()
  }
  // admin - user data
  const onClick2UserDataCancel = async (e) => {
    e.preventDefault()
    setEdit2(false)
  }
  const onClick2UserEdit = (x) => {
      if(data.id==x.id && data.role == 'admin'){
        setEdit2(true)
        setUpdateUserId(null)
        setUpdateData(x)
      }else if(data.role == 'admin'){
        setUpdateUserId(x.id)
        setEdit2(false)
        setUpdateData(x)
      }else{
        setEdit(true)
      }
  }
  const onClick2UserUpdate = async (e) => {
    e.preventDefault()
    try {
      const { data: res } = await axios.post(`${APIURL2}/user/update`, {user:data,update_user:updateData})
      setMsg(res.msg)
      setData({...data, username:res.user.username, email:res.user.email, pwd:res.user.pwd, role:res.user.role, users:res.users})
      setUpdateUserId(null)
      const remove = setTimeout(() => {
        setMsg('')
        setEdit2(false)
      }, 3000)
      return () => clearTimeout(remove)
    } catch (err) {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.data.msg)
      }
    }
    setErr('')
  }
  const onClick2UserDelete = async(x) => {
    try{
      const { data: res } = await axios.post(`${APIURL2}/user/delete`, {user:data,remove_user:{id:x}})
      setMsg(res.msg)
      if(data.id==x){
        localStorage.removeItem('token')
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }else{
        setData({...data, user:res.user, users:res.users})
      }
  } catch(err){
    if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        setErrMsg(err.response.msg)
    }
  }
  }
  // admin - users data
  const onClick2UsersCancel = async (e) => {
      e.preventDefault()
      setUpdateUserId('')
      setUpdateData({ lastname:'', firstname:'', email: '', pwd: '', role:'' })
  }
  return (
    <>
      <div className='login-container'>
      <div className='notification-container'>
      {msg && <div className='notification flex flex-row justify-between'><div>{msg}</div><div><IoIosClose onClick={onClick2CloseMsg}/></div></div>}
      {errMsg && <div className='account-err-msg flex flex-row justify-between'><div>{errMsg}</div><div><IoIosClose onClick={onClick2CloseErrMsg}/></div></div>}
      </div>
      {data.role === 'admin'? 
      <div>
        <div className='login-nav flex-row'>
        <div><span>Admin</span>Main Page</div>
        <div className='flex'>
          <div className='logout-container'>
          Login Token Ticket:<br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          <IoTimeSharp />{timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
          </div>
        </div>
        </div>
        <h4>Member Accounts</h4>
        <div className='login-admin-container'>
          <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
          </div>
          <>{(data.users).map((x, key) =>
            <>{updateUserId !== x.id?
            <div className='item flex flex-row justify-items-start'>
            <div>{key+1}</div>
            <div>{x.id}</div>
            <div>{x.username}</div>
            <div>{x.email}</div>
            <div>*****</div>
            <div>{x.role}</div>
          <div className='flex btn-pc'><button onClick={()=> onClick2UserEdit(x)} className='edit-btn'>Edit</button>
          <button onClick={()=> onClick2UserDelete(x.id)} className='delete2-btn'>Delete</button></div>
          <div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UserEdit(x)}></div></div>
          {/* <MdEdit onClick={()=> onClick2UserEdit(x)}/> */}
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={()=> onClick2UserDelete(x.id)}></div></div></div>
          </div> : <div className='item-edit flex flex-row justify-items-start text-left'>
          <div>{key+1}</div>
          <div>{x.id}</div>
          <div><input className='inputA' type='text' name='username' defaultValue={x.username} onChange={onChange2Input} />
          </div>
            <div><input className='inputA' type='email' name='email' defaultValue={x.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={x.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={x.role}
            onChange={(e)=>onChange2Select(e.target.value)}>
            <option value="admin">admin</option>
            <option value="normal">normal</option>
            </select></div>
            {err && <div className='create-new-account-err'>{err}</div>}
            <div className='flex'>
            <button onClick={onClick2UsersCancel} type='button'className='cancel-btn'>Cancel</button>
            <button onClick={onClick2UserUpdate} type='button' className='update2-btn'
            disabled={!(updateData.email && updateData.pwd && updateData.username)}>Update</button></div>
            </div>
            }</>
          )}</>
          <br/><br/>
          <h4>Your Account</h4>
          <div className='login-admin-container-user'>
            <div className='ttl flex justify-items-start'>
            <div></div>
            <div>Account ID</div>
            <div>Username</div>
            <div>Email</div>
            <div>Pwd</div>
            <div>Role</div>
            <div></div>
            </div>
          {edit2 !== false ?
            <div className='item-edit flex justify-items-start text-left'>
            <div></div>
            <div className='text-left'>{data.id}</div>
            <div><input className='inputA' type='text' name='username' defaultValue={data.username} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='email' name='email' defaultValue={data.email} onChange={onChange2Input} /></div>
            <div><input className='inputA' type='text' name='pwd' defaultValue={data.pwd} onChange={onChange2Input} /></div>
            <div><select name="role" defaultValue={data.role}
            onChange={(e)=>onChange2Select(e.target.value)}>
            <option value="admin">admin</option>
            <option value="normal">normal</option>
            </select></div>
            <div className='flex'><button
            className='cancel-btn' onClick={onClick2UserDataCancel}
            >Cancel</button><button
            onClick={onClick2UserUpdate}
              className='text-left update2-btn'
              type='button'
              disabled={!(updateData.username&&updateData.email&&updateData.pwd)}
            >Update</button></div>
           {err && <div className='create-new-account-err'>{err}</div>} 
          </div> : <div className='item flex flex-row justify-items-start'><div></div><div>{data.id}</div><div>{data.username}</div><div>{data.email}</div><div>*****</div><div>{data.role}</div><div className='flex btn-pc'><button className='edit-btn' onClick={()=> onClick2UserEdit(data)}
          >Edit</button><button className='delete2-btn' onClick={()=> onClick2UserDelete(data.id)}>Delete Account</button></div><div className='flex btn-sp'>
          <div className='edit2-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringMdEdit}")`}}>
          <div className='edit2-btn-bg' onClick={()=> onClick2UserEdit(data)}></div></div>
          <div className='delete3-btn' style={{maskImage: `url("data:image/svg+xml,${svgStringIoIosClose}")`}}>
          <div className='delete3-btn-bg' onClick={()=> onClick2UserDelete(data.id)}></div></div></div></div>}
          </div>
          <br />
        </div> 
        </div>:<>
        <div className='login-nav'>
          <div>Main Page</div>
          <div className='flex'>
          <div className='logout-container'>
           • This Login Token is available for <br/> {new Date(data.iatstring).getUTCMonth() + 1}.{new Date(data.iatstring).getUTCDate()}.{new Date(data.iatstring).getUTCFullYear()} {new Date(data.iatstring).toLocaleTimeString()} - {new Date(data.expstring).getUTCMonth() + 1}.{new Date(data.expstring).getUTCDate()}.{new Date(data.expstring).getUTCFullYear()} {new Date(data.expstring).toLocaleTimeString()}<br/>
          • You have {timer} left
          </div>
        <div>
          <button
            className='logout-btn flex'
            onClick={onClick2Logout}
          ><FiLogOut />Logout</button>
         </div>
        </div>
        </div>
        <div className='login-contents-container'
        >
          <div className='menu-item mb-4'><b>Change account details</b>
           {edit ? <button className='cancel-btn' onClick={() => setEdit(!edit)}>Cancel</button>:<button className='cancel-btn'
            onClick={()=> onClick2UserEdit(data)}>Edit</button>}
          </div>
          {edit ? <>
            <b>Account ID:{data.id}</b>
            <div>
            <label>Username</label><br/>
            <input className='create-new-account-input' type='text' name='username' defaultValue={updateData.username} onChange={onChange2Input} />
            </div>
            <div>
            <label>Email Address</label><br/>
            <input className='create-new-account-input' type='email' name='email' defaultValue={updateData.email} onChange={onChange2Input} />
            </div>
            <div>
            <label>Password</label><br/>
            <input className='create-new-account-input' type='text' name='pwd' defaultValue={updateData.pwd} onChange={onChange2Input} />
            </div>
            Role:{updateData.role}<br/>
            {err && <div className='create-new-account-err'>{err}</div>}
            <button
            className='update-btn'
            onClick={onClick2UserUpdate}
            disabled={!(updateData.username&&updateData.email&&updateData.pwd)}
            >Update</button>
          </> : <>Account ID: {data.id}<br />Username: {data.username}<br />Email Address: {data.email}<br />Password: *****
          <br />Role: {data.role}</>}
          <br />
          <div className='menu-item'>
            <b>Delete Account</b><br />
            <button
              className='delete-btn'
              onClick={()=> onClick2UserDelete(data.id)}
            >Delete Account</button>
          </div>
        </div>
        </>}
      </div>
    </>
  )
}
export default LoginWithDifferentDB