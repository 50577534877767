import React, { useState, useEffect } from 'react'
import './style.css'
import styled from 'styled-components'
import axios from 'axios'
import { FiUpload } from 'react-icons/fi'
import { VscNewFile } from "react-icons/vsc"
import { MdOutlineNavigateNext, MdOutlineNavigateBefore} from "react-icons/md"
import { } from "react-icons/md";
import {MdOutlineClose, MdOutlineArrowDropDown} from 'react-icons/md'
import { FaUser } from "react-icons/fa"
import { BiSolidLandscape } from "react-icons/bi"
import { LiaObjectUngroupSolid } from 'react-icons/lia'
import { MdOutlineLabel } from 'react-icons/md'
import {AiOutlineSetting} from 'react-icons/ai'
import {useSelector, useDispatch } from 'react-redux'
//import { usePDF, Page, Image, View, Document, StyleSheet} from'@react-pdf/renderer'
import { pdfjs } from 'react-pdf'
import {TemplateDefaultData, TemplateData,SelectList, CompanyData,ItemData, TransactionData} from './asset/template-random-item-values'
// import {LogoImg1, LogoImg2, LogoImg3, LogoImg4, LogoImg5, LogoImg6, LogoImg7, LogoImg8, LogoImg9, LogoImg10, LogoImg11, LogoImg12, LogoImg13, LogoImg14, LogoImg15, LogoImg16}
// from './asset/template-random-item-img/logo'
import {A,B,C} from './asset/template-background-img'
// from './asset/template-random-item-img/logo'
// import {StampImg1, StampImg2, StampImg3, StampImg4, StampImg5, StampImg6, StampImg7, StampImg8, StampImg9, StampImg10, StampImg11, StampImg12, StampImg13, StampImg14, StampImg15, StampImg16}
// from './asset/template-random-item-img/stamp'
import previewDefaultPngData from './asset/template-background-img/1.png'
//import TestImg from './Component/1.png'
//↓ do not delete
//import * as pdfjs from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.mjs'
const APIURL1 = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV1}` : `${process.env.REACT_APP_DB_ENV_TEST1}`
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4',
//   },
//   pageBackground: {
//     position: 'relative',
//     minWidth: '100%',
//     minHeight: '100%',
//     display: 'block',
//     height: '100%',
//     width: '100%',
//     zIndex:100
//   }
// })
// const companyLogo = [
//   LogoImg1, LogoImg2,LogoImg3, LogoImg4, LogoImg5, LogoImg6, LogoImg7, LogoImg8, LogoImg9, LogoImg10, LogoImg11, LogoImg12, LogoImg13, LogoImg14, LogoImg15, LogoImg16
// ] 
// const companyStamp = [
//   StampImg1, StampImg2,StampImg3, StampImg4, StampImg5, StampImg6, StampImg7, StampImg8, StampImg9, StampImg10, StampImg11, StampImg12, StampImg13, StampImg14, StampImg15, StampImg16
// ] 
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjs
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()

const StyledContainer = styled.div`
  padding: 0 20px;
  font-size: 12px;
`
export default function CvGenerator() {
    // pg data
    const [templateData, setTemplateData] = useState(TemplateData)
    // navigation switch
    const [selectedTemplate, setSelectedTemplate] = useState(TemplateData[0])
    const [selectedId, setSelectedId] = useState(TemplateData[0].template_id)
    const [selectedOriginal, setSelectedOriginal] = useState(previewDefaultPngData) //generated sample only generatng
    const [createNew, setCreateNew] = useState(false)   
    const [updateName, setUpdateName] = useState(false)
     //new & update template item
     const [templateUpdateData, setTemplateUpdateData] = useState(TemplateData[0])
    const [generateSample, setGenerateSample] = useState(false)
    const [createBox, setCreateBox]=useState(false)
    const [labelBox, setLabelBox]=useState(false)
    const [save, setSave]=useState(false)
    const [download, setDownload]=useState(false)
    const [boxlistPage, setBoxlistPage]=useState(1)
    const [client, setClient] = useState({x:0,y:0})
    const [offset, setOffset] = useState({x:90,y:250})
    const [currentPoint, setCurrentPoint] = useState({x:0,y:0})
    const [startPoint, setStartPoint] = useState({x: 78, y: 100})
    const [endPoint, setEndPoint] = useState({x: 225, y: 130})  
    const [dataLoaded,setDataLoaded] = useState('false')
    //createBox
    const [selectedBoxlist1, setSelectedBoxlist1] = useState(selectedTemplate.boxlist)
    // TemplateData[0].boxlist
    const [selectedBox1, setSelectedBox1] = useState({top:0, left:0})
    const [tl1, setTl1] = useState(false)
    const [tl2, setTl2] = useState(false)
    const [tw, setTw] = useState(false)
    const [wh, setWh] = useState(false)
    const [lh, setLh] = useState(false)
    const [tlDif, setTlDif] = useState({left:0,top:0})
    const [editBox, setEditBox] = useState(false)
    const [companyData, setCompanyData] = useState(CompanyData)
    const [itemData, setItemData] = useState(ItemData)
    const [transactionData, setTransactionData] = useState(TransactionData)
    const [pdfFile1, setPdfFile1] = useState(previewDefaultPngData) //byteasample
    const [pngFile1, setPngFile1] = useState() //from user upload
    const [pngFile_2_and_3, setPngFile_2_and_3] = useState(previewDefaultPngData)
    
    // practice
    const [pngFile4, setPngFile4] = useState(previewDefaultPngData) //byteasample
    const [pngFile5, setPngFile5] = useState(previewDefaultPngData) //byteasample
    //new png
    const [pngFile6, setPngFile6] = useState(previewDefaultPngData) //byteasample
    const [pngFile7, setPngFile7] = useState(previewDefaultPngData) //byteasample
    const [pngFile8, setPngFile8] = useState(previewDefaultPngData) //byteasample
    const [type, setType] = useState('portlait')
    //pdf script data to generate pdf 
    const [generatenumber, setGeneratenumber] = useState(1)
    const [companyLogoGeneratedNum1, setCompanyLogoGeneratedNum1] = useState(0)
    const [companyData1, setCompanyData1] = useState(CompanyData[0])
    const [companyData2, setCompanyData2] = useState(CompanyData[1])
    const [itemData1, setItemData1] = useState()//ItemData[0]
    const [itemData2, setItemData2] = useState()//ItemData[1]
    const [itemData3, setItemData3] = useState()//ItemData[2]
    const [transactionData1, setTransactionData1] = useState(TransactionData[0])
    const [stamp, setStamp] = useState(false)
    const [logo, setLogo] = useState(false)
    useEffect(()=>{
      if (dataLoaded !== 'true'){
        // try {
        //   axios.get(`${APIURL1}/api/pg/template`,{responseEncoding: 'base64'}).then(async(res)=> {  
        //   setTemplateData(res.data) 
        //   setSelectedTemplate(res.data[0]) 
        //   setTemplateUpdateData(res.data[0]) 
        //   setSelectedId(res.data[0].template_id)
        //   setSelectedOriginal(`data:image/png;base64,${res.data[0].original_encode}`)
        //   setSelectedBoxlist1(res.data[0].boxlist)
        //   setType(res.data[0].type)
        //   // test123 setPngFile_2_and_3(`data:image/png;base64,${res.data[0].encode}`)
          
        // } )} catch (error) { console.log(error)}
      const x2 = templateData.find((x) => x.template_id === Number(selectedTemplate.template_id))
  //     if(x2.boxlist.find((x)=>(x.name).slice(0,(x.name).length-2) === 'company_logo') !==
  // undefined){ setLogo(true)
  //     } else { setLogo(false)}
  //     if(x2.boxlist.find((x)=>(x.name).slice(0,(x.name).length-2) === 'company_stamp') !==
  // undefined){
  //       //console.log('includes stamp', x2.boxlist.find((x)=>(x.name).slice(0,(x.name).length-2) === 'company_stamp'))
  //       setStamp(true)
  //     } else {setStamp(false)}
        setSelectedTemplate(x2)
        setSelectedBoxlist1(x2.boxlist)
        setType(x2.type)
        previewImg(1)
        try { axios.get(`${APIURL1}/api/pg/company`).then(async(res) => { 
          await setCompanyData(res.data)
               console.log('companyData',res.data)
         } ) } catch (error) { console.log(error) }
          try { axios.get(`${APIURL1}/api/pg/transaction`).then(async(res) => { 
            await setTransactionData(res.data)  
               console.log('transactionData',res.data)
         } ) } catch (error) { console.log(error) }
          try { axios.get(`${APIURL1}/api/pg/product`).then(async(res) => { 
            await setItemData(res.data)
               console.log('ItemData',res.data)
         } ) } catch (error) { console.log(error) }     
       setDataLoaded('true') 
      }
    },[dataLoaded, templateData,selectedTemplate])
    // convert to blob data
    function DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
      const x = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
          x[i] = byteString.charCodeAt(i)
      return new Blob([x], { type: mimeString })
    }

    // create new template
    const onChange2InputTempItemInfo = ({currentTarget:x})=> {
      console.log(x.name, x.value)
      setTemplateUpdateData({...templateUpdateData, [x.name]:x.value})
      console.log(templateUpdateData.name)
    }
    const onChange2InputTempItemBoxlist = ({currentTarget:x},i)=> {
     setSave(true)
     console.log('b1-0',i, x.name, x.value)
     var b1 = [...templateUpdateData.boxlist] //copy current boxlist
     b1[i] = {...b1[i], [x.name]:x.value} //update copied boxlist
     templateUpdateData['boxlist'] = b1 //updated to update data
     setTemplateUpdateData(templateUpdateData)
    }
    const selectedBoxlist1_3 = () => { 
      const i = selectedBoxlist1.indexOf(selectedBoxlist1.filter((x) => x.boxId === selectedBox1.boxId)[0])
      const b1 = [...selectedBoxlist1]
      b1[i] = selectedBox1
      setSelectedBoxlist1(b1)
    }
    //change template
    const onChange2Select = async(x1)=> {
      const x2= templateData.find((x) => x.template_id === Number(x1))
      setTemplateUpdateData(x2)
      setSelectedTemplate(x2) 
      console.log('selected',JSON.stringify(templateUpdateData.boxlist))
      // // setPngFile_2_and_3(`data:image/png;base64,${x2.encode}`)   
      // //console.log('template changed',x1, x2.name, x2.encode.slice(100,105))
      // //find template_id:x1 of templateData index and encode data
      // const x4 = templateData.filter(y => y.template_id === Number(x1))[0].encode
      // const x7 = templateData.filter(y => y.template_id === Number(x1))[0].template_id
      // const x6 = templateData.filter(y => y.template_id === Number(x1))[0].name
      // console.log('---x5---',templateData.filter(y => y.template_id === Number(x1).length))
      // console.log('----x4-----',`data:image/png;base64,${x4}`)
      // console.log('------',x7,x6)
      // setPngFile6(`data:image/png;base64,${x4}`)
      // setPngFile8(x4)
      // setSelectedId(Number(x1)) //ok

  //     if(x2.boxlist.find((x)=>(x.name).slice(0,(x.name).length-2) === 'company_logo') !==
  // undefined){
  //       setLogo(true)
  //     } else {setLogo(false)}
  //     if(x2.boxlist.find((x)=>(x.name).slice(0,(x.name).length-2) === 'company_stamp') !==
  // undefined){
  //       setStamp(true)
  //     } else {setStamp(false)
  //     }
  
  
    
      // setTemplateUpdateData(x2)
      // setSelectedBoxlist1(x2.boxlist)
      // setType(x2.type)
      // console.log('selected img changed')
      // previewImg(1)
    }
    const onClick2NameBoxlistSave = async() => {
     //save boxList 
      await axios.post(`${APIURL1}/api/pg/template/update/nameboxlist`, {template_id:selectedTemplate.template_id, name:templateUpdateData.name, boxlist:JSON.stringify(selectedTemplate.boxlist)})
      .then(async x => {
        setSave(false)
        console.log('received from backend',x.data.data2)
        setUpdateName(!updateName)
        setTemplateData(x.data.data1)
        setSelectedTemplate(x.data.data2)
        setTemplateUpdateData(x.data.data2)
        
      })
      
    }
    const onClick2CreateNew = async() => {
      console.log('clicked create')
      await axios.post(`${APIURL1}/api/pg/template/new`, {name:templateUpdateData.name, type:templateUpdateData.type, position:templateUpdateData.position,boxlist:JSON.stringify(templateUpdateData.boxlist)})
      .then(async x => {
      setTemplateData(x.data.data1)
      setSelectedTemplate(x.data.data2)
      setTemplateUpdateData(x.data.data2)
      setSelectedBoxlist1(x.data.data2.boxlist)
      setCreateNew(!createNew)
      setTemplateUpdateData({type:null}) 
      setPngFile1()
      })
    }
    const onChange2Edit = async(x1) => {
      const x2= templateData.find((x) => x.template_id === Number(x1))
      //console.log('selected item',x2.name)
      //setSelectedTemplate(x2)
      
      setCreateBox(!createBox)

      setUpdateName(!updateName)
    }
    const onClick2Delete = async() => {
      console.log('clicked delete')
      await axios.post(`${APIURL1}/api/pg/template/delete`, {template_id:selectedTemplate.template_id})
        .then(async x => {
          setTemplateData(x.data.data1)
          setSelectedTemplate(x.data.data2)
          setTemplateUpdateData(x.data.data2)
          setSelectedBoxlist1(x.data.data2.boxlist)
          
        })
        setUpdateName(!updateName)
        
    }
    //pdf > resize png
    const scriptImg = async (data) => {
        const canvas1 = document.createElement('canvas')
        canvas1.setAttribute('className', 'canv')
        const pdf = await pdfjs.getDocument({ data }).promise
        var page = await pdf.getPage(1)
        var viewport = page.getViewport({ scale: 1 })
        if(viewport.width < viewport.height){
          viewport = page.getViewport({ scale: 690/viewport.width })
          canvas1.height = 980
          canvas1.width = 690
          canvas1.textAlign = 'center'
          canvas1.margin = 'auto'
          var context = {
            canvasContext: canvas1.getContext('2d'),
            viewport: viewport,
          }
          await page.render(context).promise
          let img = canvas1.toDataURL('image/png')
          return setPngFile1(img)
       } else{
          viewport = page.getViewport({ scale:980/viewport.width })
          canvas1.height = 690//471
          canvas1.width = 980
          canvas1.textAlign = 'center'
          canvas1.margin = 'auto'
          context = {
            canvasContext: canvas1.getContext('2d'),
            viewport: viewport,
          }
          await page.render(context).promise
          let img = canvas1.toDataURL('image/png')
          return setPngFile1(img)
        }
    }
    //png > preview png + generated text
    const onChange2NumberToGenerate = (e) => {setGeneratenumber(e.target.value)}
    function getRandomInt(max) {return Math.floor(Math.random() * max)}
    const previewImg = async (i) => {
      const companyLogoId = getRandomInt(16)
      const companyId = getRandomInt(companyData.length)
      const bankStatementId= getRandomInt(transactionData.length)
      const item1Id = getRandomInt(itemData.length)
      const item2Id = getRandomInt(itemData.length)
      const item3Id = getRandomInt(itemData.length)
      const receiver = getRandomInt(companyData.length)
      setCompanyLogoGeneratedNum1(companyLogoId)
      setCompanyData1(companyData[companyId])
      setCompanyData2(companyData[receiver])
      setTransactionData1(transactionData[bankStatementId])
      setItemData1(itemData[item1Id])
      setItemData2(itemData[item2Id])
      setItemData3(itemData[item3Id])
      function boxValue(z){
        const x = selectedTemplate.boxlist[z]
      }
      //----creating ctx script start----
      const canvas2 = document.createElement('canvas')
      canvas2.height = selectedTemplate.type === 'B'?'471':'980'
      canvas2.width = 690
      const ctx2_1 = canvas2.getContext('2d')
      ctx2_1.scale(1, 1)
      ctx2_1.strokeStyle = 'pink'
      ctx2_1.lineWidth = 2
      ctx2_1.strokeRect(0, 0, canvas2.width, canvas2.height)
      const ctx2_2 = canvas2.getContext('2d')
      ctx2_2.scale(1, 1)
      const img1_2 = document.createElement('img')
      img1_2.src = pngFile6
      if(selectedTemplate.type === 'B'){
        ctx2_2.drawImage(img1_2, 0, 0, 690, 471)
      } else{
        ctx2_2.drawImage(img1_2, 0, 0, 690, 980)
      } 
      const ctx2_3 = canvas2.getContext('2d')
      const ctx2_4 = canvas2.getContext('2d')
      const img2_2 = document.createElement('img')
      if(stamp){
        img2_2.src = companyStamp[Number(`${companyLogoGeneratedNum1}`)]
        img2_2.height = 80
        img2_2.width = 50
      } else if (logo){
        img2_2.src = companyLogo[Number(`${companyLogoGeneratedNum1}`)]
        img2_2.height = 'auto'
        img2_2.width = 150
      } 
        selectedTemplate.boxlist.map((x, i)=>{
          if(x.name ==='company_logo_B'){ return drawingCompanyLogo(i)}
          if(x.name ==='company_stamp_B'){ return drawingCompanyStamp(i)}
          return drawingBox(i)
        })
        function drawingCompanyLogo(y){
          ctx2_4.scale(1, 1)
          ctx2_4.drawImage(img2_2, selectedBoxlist1[y].left, selectedBoxlist1[y].top, selectedBoxlist1[y].width, selectedBoxlist1[y].height)
        }
        function drawingCompanyStamp(y){
          ctx2_4.scale(1, 1)
          ctx2_4.drawImage(img2_2, selectedBoxlist1[y].left, selectedBoxlist1[y].top, selectedBoxlist1[y].width, selectedBoxlist1[y].height)
        }
        function drawingBox(y){
          ctx2_3.scale(1, 1)
          ctx2_3.fillStyle = '#FFF'
          ctx2_3.fillRect(selectedBoxlist1[y].left, selectedBoxlist1[y].top, selectedBoxlist1[y].width, selectedBoxlist1[y].height)
          ctx2_3.scale(1, 1)
          ctx2_3.font = '8pt Arial'
          ctx2_3.fillStyle = 'black'
          ctx2_3.fillText(`${Object.values(selectedBoxlist1[y])[1]}`, selectedBoxlist1[y].left+5,(selectedBoxlist1[y].top)+10)
          // `${selectedBoxlist1[y][1]}`
          //`${Object.values(selectedBoxlist1[y][Object.keys(selectedBoxlist1[y])])}`
        }
        //----creating ctx script end----
        if(selectedTemplate.type==='portlait'){
           canvas2.style = 'rotate(45deg)'
        }
        var x1 = canvas2.toDataURL()
        // onClick2HexAddPdf2(i,x1)
        setSelectedOriginal(x1)
        return setPngFile7(x1)
    }
    const onClick2GeneratePdf = async(e) =>{
      e.preventDefault()
      console.log('printx', generatenumber)
      var i = 0
      var generatePdf = setInterval(
        async function count(){ 
          i++
          const x1 = Number(generatenumber)+1
          const x2 = Number(i) 
          console.log('#',i)
          previewImg(i)
          if (x2>= x1){
             clearInterval(generatePdf)
             console.log('done!')
             const res = await axios.post(`${APIURL1}/api/pg/hex/pdf/zip`, {generate_total:generatenumber, type:selectedTemplate.type})
            //  const res = await axios.post(`${APIURL1}/api/pg/template/img/script/save3`, {generate_total:generatenumber, type:selectedTemplate.type})
            // saveAs(`data:application/zip;base64, ${res.data.zip}`, 'invoice_sample.zip', { autoBom: true })
          }
      }, 1500)
      const loading=()=>{
        setDownload(!download)
      }
      loading()
      return setGeneratenumber(1)
    }
    //pdf > resize jpeg + png
    const UrlUploader1 = (url) => {
      fetch(url).then((res) => {
        res.blob().then((blob) => {
          let reader = new FileReader()
          reader.onload = (e) => {
            const data = atob(e.target.result.replace(/.*base64,/, ""))
            scriptImg(data)
            previewImg(1)
          }
          reader.readAsDataURL(blob)
        })
      })
    }
    const onClick2GenerateSample = async(e) =>{
      e.preventDefault()
        //const x = selectedTemplateEn.filter((x) => x.id === selectedTemplate.template_id)
        // test123 setPngFile_2_and_3(`data:image/png;base64,${selectedTemplate.encode}`)
      previewImg(1)
      setGenerateSample(!generateSample)
    }
    const getNameInPageSelect = (x, y) => {
        return SelectList[x][y]
    }
    //-----box function start------
    function findLength(a,b){
      if(a < 0 && b > 0){
        return b - (-a)
      }else if(a > 0 && b < 0){
        return (-b) - a
      }else if(a < 0 && b < 0){
        return (-b)-(-a)
      }else {
        return b - a
      }
    }  
   
    const onClick2StartPoint1 = (e) => {
      if(createBox === true){
        if((document.querySelector('.a:hover')) !== null){
          return setTl1(true)
        }else if((document.querySelector('.b:hover')) !== null){
          return setTw(true)
        }else if((document.querySelector('.c:hover')) !== null){
          return setWh(true)
        }else if((document.querySelector('.d:hover')) !== null){
          return setLh(true)
      }else if((document.querySelector('.label_box:hover')) !== 
  null){
        if(!editBox){
         return
        } else {
          //----edit existed box start-----
          setTlDif({left:e.clientX-selectedBox1.left,top:e.clientY-selectedBox1.top})
          return setTl2(true)
          //----edit existed box end----
        }
      } else {
        //----create new box start----- 
        console.log('creating new box...')
        setWh(true)
        const boxId = Math.floor(Math.random() * 1000000)
        setSelectedBox1({boxId:boxId, name:'empty_field', left: e.clientX, top: e.clientY})
        setSelectedBoxlist1([...selectedBoxlist1,{boxId:boxId, name:'empty_field', left: e.clientX, top: e.clientY}])
        //----create new box end----- 
      }
      }
    } 
    const onClick2CurrentPoint1 = (e,x,i) => { 
      console.log('onClick2CurrentPoint1',i, x.name, x.value)
      if(createBox === true){
        const localX = e.nativeEvent.offsetX
        const localY = e.nativeEvent.offsetY
        setCurrentPoint({ x: localX, y: localY })
        if(tl1){
          setSelectedBox1({...selectedBox1, left:e.clientX, top:e.clientY, width:selectedBox1.width+(selectedBox1.left -e.clientX)+2, height:selectedBox1.height+(selectedBox1.top -e.clientY)+2})
          selectedBoxlist1_3(selectedBoxlist1)
          return console.log('updated', selectedBoxlist1[y])
        } else if(tw){
          setSelectedBox1({...selectedBox1, top:e.clientY, width:findLength(selectedBox1.left,e.clientX),  height:selectedBox1.height+(selectedBox1.top -e.clientY)})
          return selectedBoxlist1_3(selectedBoxlist1)
        } else if(lh){
          setSelectedBox1({...selectedBox1, left:e.clientX, width:selectedBox1.width+(selectedBox1.left -e.clientX), height:findLength(selectedBox1.top,e.clientY)})
          return selectedBoxlist1_3(selectedBoxlist1)
          
        }else if(tl2){
          const selectedBoxlist1_2 = [...selectedBoxlist1]
          selectedBoxlist1_2[selectedBoxlist1.findIndex((x) => x.boxId === selectedBox1.boxId)] = {...selectedBox1, left: e.clientX - tlDif.left, top: e.clientY- tlDif.top}
          return setSelectedBoxlist1(selectedBoxlist1_2)
        } else if(wh){
          setSelectedBox1({...selectedBox1, width:findLength(selectedBox1.left,e.clientX), height:findLength(selectedBox1.top,e.clientY)})
          selectedBoxlist1_3(selectedBoxlist1)
        }
      }
    }
    const onClick2EndPoint1= (e)=> {
      if(createBox === true){
        if((document.querySelector('.a:hover')) !== null){
          return setTl1(false)
        }else if((document.querySelector('.b:hover')) !== null){
          return setTw(false)
        }else if((document.querySelector('.c:hover')) !== null){
          return setWh(false)
        }else if((document.querySelector('.d:hover')) !== null){
       return setLh(false)
      }else if ((document.querySelector('.label_box:hover')) !== 
  null){
          setEditBox(!editBox)
          if(!editBox){
            return console.log('box selected click start B') 
          }else{
            //----select existed box start-----
            setTl2(false)
            setWh(false)
            setSave(true)
            //return console.log('box unselected click end B')
            //----select existed box end-----  
            //----resize start----
            setSelectedBox1({...selectedBox1, width:findLength(selectedBox1.left,e.clientX), height:findLength(selectedBox1.top,e.clientY)})
            //console.log('updated selectedBox',selectedBox)
            //----resize end-----
          }
        } else {
          //----create new box start----- 
          setWh(true)
          setSelectedBox1({...selectedBox1, width:findLength(selectedBox1.left,e.clientX), height:findLength(selectedBox1.top,e.clientY)})
          setSelectedBoxlist1([...selectedBoxlist1,{...selectedBox1,width:findLength(selectedBox1.left, e.clientX), height:findLength(selectedBox1.top,e.clientY)}])
          console.log('box created')
          setWh(false)
          //----create new box end----- 
        }
        // setEndPoint({ x: endPointX, y: endPointY })
        // setEditBox1({...editBox1,x2:endPointX,y2:endPointY, w:localW, h: localH})
      }
    }
   const onClick2SelectBox1 = (x) => {
    if(!editBox){
      setSelectedBox1({boxId:'', top:0, left:0})
    }else{
      setSelectedBox1(x)
      console.log('---box selected---', x.boxId, x.name, 'x1',x.left, 'y1', x.top,'x2',x.height, 'y2',  x.width)
    }
   }
    const onClick2RemoveBox = (y) => {
      setSelectedBoxlist1((x) => {
        return x.filter((x) => x.boxId !== y)
      })
      console.log('box removed.')
    }
    const onClick2RemoveBox2 = (y) => {
      if(editBox){
        setSelectedBoxlist1((x) => {
        return x.filter((x) => x.boxId !== y)
        })
      }
    }
   const onClick2SelectBox1A = (x) => {
    if(editBox){
      return console.log('A')
    }
   }
   const onClick2SelectBox1B = (x) => {
    if(editBox){
      return console.log('B')
    }
   }
   const onClick2SelectBox1C = (x) => {
    if(editBox){
      return console.log('C')
    }
   }
   const onClick2SelectBox1D = (x) => {
    if(editBox){
      return console.log('D')
    }
   }
   const selectedBoxlist1_2 = (x) => {
      setSelectedBoxlist1(x)
   } 
   const onChange2Label = ({currentTarget:x}) => {
      console.log('label changed:', x.name, x.value)
      console.log(selectedBoxlist1.map(x=>x.boxId))
      const filteredData = selectedBoxlist1.filter(y => 
        y.boxId === Number(x.name)
      )[0]
      console.log('filteredData',filteredData)
      const indexOfRecord = selectedBoxlist1.indexOf(filteredData)
      console.log('indexOfRecord',indexOfRecord)
      var z = [...selectedBoxlist1]
      console.log('z',z)
      z[indexOfRecord] = {...z[indexOfRecord] , name:x.value}
      selectedBoxlist1_2(z)
      setSave(true)
   }
   const onClick2HexsPreviewPng1 = async() => {
    console.log('hex file preview clicked')
      await axios.post(`${APIURL1}/api/pg/hex/png/preview`, {id:'2'},{responseEncoding: 'base64'})
        .then(async x => {
          setPngFile5(`data:image/png;base64,${x.data.original_encode}`)
        })
   }
   const onClick2HexAddPng1 = async() => {
      await axios.post(`${APIURL1}/api/pg/hex/png/add1`, {msg:'hi'},{responseEncoding: 'base64'})
        .then(async x => {
          console.log('received data',`data:image/png;base64,${x.data.original_encode}`)
          setPngFile4(`data:image/png;base64,${x.data.original_encode}`)
        })
   }
   const onClick2HexAddPdf1 = async() => {
      await axios.post(`${APIURL1}/api/pg/hex/pdf/add1`, {msg:'hi'},{responseEncoding: 'base64'})
        .then(async x => {
          setPdfFile1(`data:application/pdf;base64,${x.data.pdf}`)
        })
   }
   //delete this function later
  //  const onClick2HexAddPdf2 = async(i, x) => {  
  //   const x1 = Number(generatenumber)+1
  //   const x2 = Number(i)
  //   if(x1 >= x2){
  //     await axios.post(`${APIURL1}/api/pg/hex/pdf/add2`, {generate_id:i,pngDataUrl:x, type:type}, {responseEncoding: 'base64'}).then(async x=> {
  //       return setPdfFile1(`data:application/pdf;base64,${x.data.pdf}`)
  //     })
  //   } else {
  //     console.log('finished printing')
  //   }
  // }
    return (
        <StyledContainer>
            <h4 className='sample-title'>CV Generator</h4>
            <div className='tag-container'>
                <span className='tech-tag'>React</span>
                <span className='tech-tag'>react-pdf</span>
                <span className='tech-tag'>DB: PostgreSQL</span>
            </div>
            <div className='sample-contents-container-007'>
              {createNew ? <div className='template-modal' >
                        <div>
                          <div className='flex justify-between mb-4 text-lg'><h2>Create New</h2>
                          <MdOutlineClose onClick={() => setCreateNew(!createNew)} />
                          </div>
                          <label for="template_name">CV Title</label><br/>
                          <input name="name" className='template_input mb-4' placeholder='MM_YYYY_CV_Don_Joe'
                          onChange={(x)=>onChange2InputTempItemInfo(x)} 
                          />
                          <label for="template_size">Template Type</label><br/>
                          <div className='flex x-size-container mb-4'>
                            <div className={templateUpdateData.type === 'A' ? 'template-type-container selected':'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'A'})}><img src={A} width="30px"/><div className="template-type"></div>
                            </div>
                            <div className={templateUpdateData.type === 'B' ? 'template-type-container selected' : 'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'B'})}><img src={B} width="30px"/><div className="template-type"></div>
                            </div>
                            <div className={templateUpdateData.type === 'C' ? 'template-type-container selected': 'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'C'})}><img src={C} width="30px"/><div className="template-type"></div>
                            </div>
                            </div>
                          <label for="template_img">Select your position</label><br/>
                          <select
                          name="position" 
                          defaultValue={selectedTemplate.position} className="mb-4"
                          onChange={onChange2InputTempItemInfo}>
                          <option value="default">Pease select position</option>{Object.keys(SelectList['select_position_name']).map((y, i) =><option
                           value={y}>{getNameInPageSelect('select_position_name', y)}</option>)}</select>
                  <label for="template_boxItem">Copy Text From Past Item:</label><br/>
                  {/* <select name='value'
                  onChange={x=> 
                  //onChange2Select(x.target.value)
                   setTemplateUpdateData({...templateUpdateData, ...TemplateDefaultData[0]})}className="mb-4"
                  ><option value="default">None</option>
                  {templateData.map(x => <option value={x.template_id}>{x.template_id} {x.name}</option>)}</select> */}
                          {/* <div className='flex uploaded-file'>File Name:{templateUpdateData.template_img ?<>{templateUpdateData.template_img.name}<MdOutlineClose
          onClick={onClick2RemovePdf}/></>
                        :<>Not Added</>}</div> */}
                        {templateUpdateData.template_img?<><img src={pngFile1} height='213'
          width='150' alt=''/><br/><></></>:''}
                        {/* <div className='changeFileBtn-container mt-4 mb-1'><label 
          htmlFor="inputPdfFile"><FiUpload className='svg-img-upload'/>{!templateUpdateData.template_img ?<>Choose File</>: <>Change File</>}</label>
                        <input className='m-auto' 
                        type="file" 
                        accept='.jpg,.pdf' 
                        name='pdf'
                        onChange={onChange2UploadPdf} id="inputPdfFile"
                        /></div> */}
                        <br/>
                        <div className='auto text-center'><button className='secondary-btn'
          onClick={onClick2CreateNew}>Create</button></div>
              </div></div> : ''}
              <div className='sample-title-container-007'>
              <div className='value-nav1'>
                  <h3>CV Title:</h3> 
                  <div className='template-ttl flex mb-1'>
                  {/* <select name='value'
                  onChange={x=> onChange2Select(x.target.value)}
                  >{templateData.map(x => <option value={x.template_id}>{x.name}</option>)}</select> */}
                  <button onClick={onClick2GenerateSample} className={generateSample ? 'secondary-btn flex selected' : 'secondary-btn flex'}>Generate Sample</button>
                  </div>
                </div>
              <div className='view-container'>
                <div className='pdfpreviewl3-1'>
              <div 
                  onMouseDown={onClick2StartPoint1}
                  onMouseMove={onClick2CurrentPoint1}
                  onMouseUp={onClick2EndPoint1}
                  className={!createBox ?'pdfpreviewl3-2 unselected' : 'pdfpreviewl3-2 selected'}
                  >
                {/* ---------edit box start -----------*/}
                {selectedBoxlist1.map((x, i)=>{
                  console.log('box list', selectedTemplate.template_id)
                  console.log('box list', selectedBoxlist1)
                  console.log('box list', selectedBoxlist1[0]['first_name'])
                  console.log('box list', Object.keys(x)[1])
                  return (
                  <>
                  <div className={selectedBox1.boxId !== x.boxId ? 'corner-box a unselected':'corner-box a selected'}
          style={{top:x.top, left:x.left}} onClick={onClick2SelectBox1A}
                  ></div>
                  <div className={selectedBox1.boxId !== x.boxId ? 'corner-box b unselected':'corner-box b selected'}
          style={{top:x.top, left:x.left+x.width-12}} 
                  onClick={onClick2SelectBox1B}
                  ></div>
                  <div className={selectedBox1.boxId !== x.boxId ? 'corner-box c unselected':'corner-box c selected'}
          style={{top:x.top+x.height-12, left:x.left+x.width-12}} onClick={onClick2SelectBox1C}
                  ></div>
                  <div className={selectedBox1.boxId !== x.boxId ? 'corner-box d unselected':'corner-box d selected'}
          style={{top:x.top+x.height-12, left:x.left}} onClick={onClick2SelectBox1D}
                  ></div>
                  <div 
                  className={selectedBox1.boxId === x.boxId? 'label_box selected':'label_box unselected'}
                  onClick={(e)=>onClick2SelectBox1(x)}
                  style={{
                    width:x.width+2,
                    height:x.height+2,
                    top:x.top,
                    left:x.left
                    }}>
                      <div><b>{i+1}@</b>{!labelBox?<>{getNameInPageSelect('select_label_name', Object.keys(x)[1])}</>: 
                      <select
          name={x.boxId} defaultValue={Object.keys(x)[1]} onChange={onChange2Label}>
                      <option value="default">{getNameInPageSelect('select_label_name',Object.keys(x)[1])}</option>
                      {Object.keys(SelectList['select_label_name']).map((y, i) =><option
                      value={y}>{getNameInPageSelect('select_label_name', y)}</option>)}
                    </select>
                    }</div><MdOutlineClose style={{fontSize:'16px'}} 
                      onClick={(e) => onClick2RemoveBox2(x.boxId)} 
                    />
                  </div>
                    </>
                  )
                })}
                </div>
               </div>
                {/* ---------edit box end -----------*/}
                {/* level1 */}
                {/* <img src={`data:image/png;base64,${templateData[Number(`${selectedId}`)].encode}`} width='50px' alt=''/><br/> */}
                {/* <img src={`data:image/png;base64,${templateData[0].encode}`} width='50px'
          alt=''/> */}
                {labelBox||createBox ? 
                <>
                {/* <img src={`data:image/png;base64,${pngFile8}`} alt='' />  */}
                {/* <img src={`data:image/png;base64,${pngFile8}`} alt='' />  */}
                {/* img with box */}
                <img className='pdfpreviewl1' src={pngFile7} alt='generated png' />
                {/* not rendering  but right data */}
                </>
                : 
                <>
                <img src={`data:image/png;base64,${selectedTemplate.encode}`}  style={{display:'none'}}
          alt='' />
                {/* <img src={companyLogo[Number(`${companyLogoGeneratedNum1}`)]} style={{display:'none'}}
          alt=''/> 
                <img src={companyStamp[Number(`${companyLogoGeneratedNum1}`)]} style={{display:'none'}}
          alt=''/>*/}
                {/* <img className='pdfpreviewl1' src={selected} alt='generated png' /> */}
                <img src={`data:image/png;base64,${selectedTemplate.original_encode}`} alt='' />
                </>
                }
                 </div>
              </div>
              <div className='sample-table-container-007'>
                {/* Preview */}
                <div className='value-nav2 flex mb-2'>
            <div className={download ? 'flex secondary-btn loading mr-1':'flex secondary-btn mr-1'}
          onClick={() => setDownload(!download)} disabled={download ? 
          true : false}>
              {download ?'Generating pdf...': <><div onClick={onClick2GeneratePdf}>Download PDF</div></>} 
                </div>
                <button onClick={() => setCreateNew(!createNew)} className={createNew ? 'secondary-btn flex selected' : 'secondary-btn flex'}>Create New</button>
                </div>
                <div className='value-nav3'>
                <button 
                  onClick={x=>onChange2Edit(x.target.value)}
              className={updateName ? 'secondary-btn flex selected' : 'secondary-btn flex'}>Edit</button><MdOutlineLabel  onClick={() => setLabelBox(!labelBox)} className={labelBox === true ? 'svg-img selected': 'svg-img'}/>
                </div>
                {generateSample ? <div className='flex justify-between mb-4 text-lg'><h2>Sample Generated</h2>
                 <MdOutlineClose onClick={() => setGenerateSample(!generateSample)} />
                </div>:''}
                <div className='template-boxlist-container mb-2'> 
                {updateName ? <div className='template-boxlist-edit' ><div>
                  <div className='flex justify-between mb-4 text-lg'>
                    <h2>Edit Template</h2>
                    <MdOutlineClose onClick={()=>setUpdateName(!updateName)} />
                  </div>
                {boxlistPage == 1?<>
                  <div className='box-meter mr-2'>
                {/* W:{editBox1.w} H:{editBox1.h} */}
                <b>documentID {selectedTemplate.template_id}</b><br/>
               
                {/* <b>Real Box Meter</b><br/>
                Global:({client.x}, {client.y})<br/>
                Offset:({offset.x}, {offset.y})<br/>
                Local:({currentPoint.x}, {currentPoint.y})<br/>
                Start:({startPoint.x}, {startPoint.y})<br/>
                End:({endPoint.x}, {endPoint.y})<br/> */}
               {selectedBoxlist1.map((x, i)=>{
                   <div>
                   <p>{x.boxId}</p>
                  {/* <b>{i+1}@</b>{!labelBox?<>{getNameInPageSelect('select_label_name', Object.keys(x)[1])}</>: 
                        <select
            name={x.boxId} defaultValue={Object.keys(x)[1]} onChange={onChange2Label}>
                        <option value="default">{getNameInPageSelect('select_label_name',Object.keys(x)[1])}</option>
                        {Object.keys(SelectList['select_label_name']).map((y, i) =><option
                        value={y}>{getNameInPageSelect('select_label_name', y)}</option>)}
                      </select> 
                      }*/}
                    </div>
                })} 
                </div>
                
             {/*  <div className='template-boxlist-item easy-sample mb-4'>
                <h3>Easy Sample</h3>
                <h3>Select your position</h3>
                <select
                  name="position" defaultValue={selectedTemplate.position} 
                  onChange={x=>onChange2InputTempItemInfo(x)}>
                  <option value="default">Pease select position</option>
                  {Object.keys(SelectList['select_position_name']).map((y, i) =><option
                  value={y}>{getNameInPageSelect('select_position_name', y)}</option>)}
                </select>
                <label for="template_size">Template Type</label> 
                <div className='flex x-size-container mb-4'>
                  <div className={templateUpdateData.type === 'A' ? 'template-type-container selected':'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'A'})}><img src={A} width="30px"/><div className="template-type"></div>
                  </div>
                  <div className={templateUpdateData.type === 'B' ? 'template-type-container selected' : 'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'B'})}><img src={B} width="30px"/><div className="template-type"></div>
                  </div>
                  <div className={templateUpdateData.type === 'C' ? 'template-type-container selected': 'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'C'})}><img src={C} width="30px"/><div className="template-type"></div>
                  </div>
                </div>
              </div>*/}
              
                <label for="template_name">Template Name</label>
                <input name="name" className='template-boxlist-item mb-2' defaultValue={selectedTemplate.name} onChange={(x)=>onChange2InputTempItemInfo(x)} />
                
                {selectedBoxlist1.map((x, i)=> <>
               <b>{x.boxId} {getNameInPageSelect('select_label_name', Object.keys(x)[1])}: {Object.values(x)[1]}</b><br/>
               Top:{x.top} Left:{x.top}<br/> 
               Height:{x.height}<br/> 
               Width:{x.width}<br/>
               </>).slice(0,2)}<br/>
               </> :''}

                {Object.keys(SelectList.select_label_name).map((y,i)=><div className='template-boxlist-item mb-2'>{getNameInPageSelect('select_label_name', y)}
                <input name={y} className='template_input' defaultValue={selectedTemplate.boxlist[i][y]}
                onChange={x=>onChange2InputTempItemBoxlist(x, i)} />
                Top: <input name='top' className='template_input' defaultValue={selectedTemplate.boxlist[i]['top']}
                onChange={x=>onChange2InputTempItemBoxlist(x, i)} />
                Left: <input name='left' className='template_input' defaultValue={selectedTemplate.boxlist[i]['left']}
                onChange={x=>onChange2InputTempItemBoxlist(x, i)} />
                Height: <input name='height' className='template_input' defaultValue={selectedTemplate.boxlist[i]['height']}
                onChange={x=>onChange2InputTempItemBoxlist(x, i)} />
                Width: <input name='width' className='template_input' defaultValue={selectedTemplate.boxlist[i]['width']}
                onChange={x=>onChange2InputTempItemBoxlist(x, i)} />
               </div>).slice(0,2)}

               

                  {boxlistPage == 2?<>{Object.keys(SelectList.select_label_name).map((y,i)=><div className='template-boxlist-item mb-2'>{getNameInPageSelect('select_label_name', y)}
                <input name={y} className='template_input' 
                defaultValue={(Object.values(selectedTemplate.boxlist[i])[1])}
                   onChange={(x)=>onChange2InputTempItemBoxlist(x, i)} />
                   </div>).slice(10,16)}</> :''}
                  <div className='auto text-center mt-2'><button onClick={onClick2Delete} className='secondary-btn'>Delete</button><button onClick={onClick2NameBoxlistSave} className='secondary-btn ml-2'>Save</button></div>
              </div></div> : <>
              <div className='template-boxlist-display mb-4'>
                  <label for="template_name">Template Name</label>{selectedTemplate.name}</div>
              <div className='template-boxlist-display mb-4'>
                <label for="template_position">Applying position</label>
                {getNameInPageSelect('select_position_name', selectedTemplate.position)}
                </div>
                <div className='template-boxlist-display mb-4'>
                  <label for="template_name">Template Type</label>
                <div className='flex x-size-container mb-4'>
                  <div className={selectedTemplate.type === 'A' ? 'template-type-container selected':'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'A'})}><img src={A} width="30px"/><div className="template-type"></div>
                  </div>
                  <div className={selectedTemplate.type === 'B' ? 'template-type-container selected' : 'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'B'})}><img src={B} width="30px"/><div className="template-type"></div>
                  </div>
                  <div className={selectedTemplate.type === 'C' ? 'template-type-container selected': 'template-type-container'} onClick={(x)=> setTemplateUpdateData({...templateUpdateData,type:'C'})}><img src={C} width="30px"/><div className="template-type"></div>
                  </div>
                </div>
                </div>
                  {boxlistPage == 1?<>{Object.keys(SelectList.select_label_name).map((y,i)=><>
                  <div className='template-boxlist-display mb-2'><label for="template_boxlist">{getNameInPageSelect('select_label_name', y)}</label>
                 {selectedTemplate.boxlist[i][y]}</div></>
                  ).slice(0,10)}</> :''}
                  {boxlistPage == 2?<>{Object.keys(SelectList.select_label_name).map((y,i)=><><div className='template-boxlist-display mb-2'><label for="template_boxlist">{getNameInPageSelect('select_label_name', y)}</label> {selectedTemplate.boxlist[i][y]}</div>
                  </>).slice(10,16)}</> :''}
                   </>}  
                </div>
               <div className='flex justify-between mb-2 value-nav4'>
               {boxlistPage == 1?<div></div>:<MdOutlineNavigateBefore onClick={() => setBoxlistPage(boxlistPage-1)} />}
               {boxlistPage == 2?<div></div>:<MdOutlineNavigateNext onClick={() => setBoxlistPage(boxlistPage+1)}/>}
                </div>
                <img src={`data:image/png;base64,${selectedTemplate.original_encode}`} alt='' width='60px'/>
                {/* selected img: {pngFile6}<br/> */}
                <h4 className='mb-4'>Template List
                :</h4>
                <ul>
                  {/* {templateData.map(x=><li className='flex'>・{x.template_id} {x.name}
                  <img src={`data:image/png;base64,${x.original_encode}`} alt='' width='60px'/></li>)} */}
                </ul><br/>
                <br/><h4>Included Items:</h4>
                <div className='box-item-in-text-container'>{selectedBoxlist1.map((x, i)=>{
                  return(
                    <div className='box-item-in-text'>{i+1}. {getNameInPageSelect('select_label_name', x.name)}<MdOutlineClose
          onClick={(e) => onClick2RemoveBox(x.boxId)} /></div>
                  )
                } 
              )}</div>
                <button onClick={onClick2HexsPreviewPng1}>[REMOVE] template_id 2 ▶ show</button>
                <img src={pngFile5} height='213' width='150' alt=''/>
                <button onClick={onClick2HexAddPng1}>[REMOVE] local 2.png  ▶ Hex ▶ (buffer) ▶ add  ▶ show</button>
                <img src={pngFile4} height='213' width='150' alt=''/>
                <button onClick={onClick2HexAddPdf1}>Hex test local file(buffer) ▶ add pdf</button>
                <iframe src={pdfFile1} height='150' width='150' alt=''
          title='a'></iframe>
          
                {/* <button onClick={onClick2HexAddPdf2}>Hex test generate files(pngDataUrl) ▶ send compiled zip pdf ▶ remove generated files in db</button> */}
              </div>
            </div>
        </StyledContainer >
    )
}



  

