import React, { useState } from 'react'
import { render } from 'react-dom'
import './scss/index.css'
import { Hero, Footer } from './component'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import { PlotGenerator,CvGenerator, Test, LoginWithDifferentDB, 
  LinearGenerator, PieChartGenerator, GraphingGenerator, SearchFilterTool, SearchFilterTool2 } from './component'
import { IoIosMenu } from 'react-icons/io'
import { BiChevronsRight } from 'react-icons/bi'
import { VscCircleLargeFilled } from 'react-icons/vsc'
export default function App() {
  const [selectedPage, setSelectedPage] = useState('All')
  const [menu, setMenu] = React.useState(false)
  const onClick2Close = () => {
    setMenu(!menu)
  }
  const onClick2Page = (e, ttl) => {
    e.preventDefault()
    setMenu(!menu)
    setSelectedPage(ttl)
  }
  const pages = [
    {
      ttl: 'All',
      page: <><Container maxWidth="md" id='contact'
      ><WorkSample /></Container><Container maxWidth="md" id='contact'
      ><Profile /></Container><Container maxWidth="md" id='contact'
      ><Contact /></Container></>
    },
    {
      ttl: 'Profile',
      page: <><Profile /></>
    },
    {
      ttl: 'Dev',
      page: <><Dev /></>
    },
    {
      ttl: 'Work Sample',
      page: <><WorkSample /></>
    },
    // {
    //   ttl: 'Tutorial',
    //   page: <Container maxWidth="md" id='tutorials'>
    //   <Tutorial />
    // </Container>
    // },
    {
      ttl: 'Contact',
      page: <Container maxWidth="md" id='contact'
      ><Contact /></Container>
    }
  ]
  return (
    <div className='App'>
      <header className='header'>
        <Container maxWidth="md">
          <nav className='nav-container'>
            <button onClick={() => setSelectedPage('All')} className={selectedPage === 'All' ? 'nav-page-ttl-active' : 'nav-page-ttl'}
              style={{
                textDecoration: 'none', color: '#888784', cursor: 'default', border: 'none',
                backgroundColor: 'transparent'
              }}><h1>Portfolio<span>Kaede Nakashima</span></h1></button>
            <div className='nav'>
              {pages.filter((x, i) => i > 0).map((x, i) => (<button
                key={i}
                onClick={() => setSelectedPage(x.ttl)}
                className={selectedPage === x.ttl ? 'nav-page-ttl-active' : 'nav-page-ttl'}
                style={{
                  textAlign: 'center', textDecoration: 'none', color: '#888784',
                  fontWeight: '400', margin: 'auto 8px', cursor: 'default', border: 'none',
                  backgroundColor: 'transparent', fontFamily: 'raleway', fontSize: '18px'
                }}
              >{x.ttl}</button>
              ))}
            </div>
            <nav className="navbar">
              <button type="button" className="nav-btn" onClick={onClick2Close}>
                <IoIosMenu />
              </button>
            </nav>
            <aside className={`sidebar ${menu ? 'show-sidebar' : ""}`}>
              <button className="close-btn" menu={menu} onClick={onClick2Close}>
                x
              </button>
              <div className='sp-nav-container'>
                {pages.map((x, i) => (<button
                  key={i}
                  onClick={(e) => onClick2Page(e, x.ttl)}
                  className={selectedPage === x.ttl ? 'nav-page-ttl-active' : undefined}
                  style={{
                    textAlign: 'center', textDecoration: 'none', color: '#888784',
                    fontWeight: '400', margin: 'auto 8px', cursor: 'default', border: 'none',
                    backgroundColor: 'transparent', fontFamily: 'raleway', fontSize: '18px'
                  }}
                >{x.ttl}</button>
                ))}
              </div>
            </aside>
          </nav>
        </Container>
      </header>
      <section className='top-banner-container' id='home'>
        <Hero />
      </section>
      {pages.map((x, i) => x.ttl === selectedPage && <div key={i}>{x.page}</div>)}
      <Footer />
    </div>
  )
}
const SectionTitle = ({ title }) => {
  return (
    <h2 className='section-title'>{title}</h2>
  )
}
const workSamples = [
  // {
  //   ttl:'Plot Generator',
  //   item: <PlotGenerator/>
  // },
  // {
  //   id:'sample-contents-container-007',
  //   ttl: 'Img/Doc Generator',
  //   item: <CvGenerator />
  // },
  // {
  //   id:'sample-contents-container-003',
  //   ttl: 'Test',
  //   item: <Test />
  // },
  {
    id:'sample-container-006,sample-contents-container-006',
    ttl: 'Linear Generator',
    item: <LinearGenerator />
  },
  {
    id:'sample-container-002,sample-contents-container-002',
    ttl: 'Account Manage/Token Expirery Timer/Login&Logout/Admin Role',
    item: <LoginWithDifferentDB />
  },
  {
    id:'sample-contents-container-005',
    ttl: 'Pie Chart Generator',
    item: <PieChartGenerator />
  },
  {
    id:'sample-title-container-004, sample-contents-container-004',
    ttl: 'Graphing Generator',
    item: <GraphingGenerator />
  },
  {
    // id:'sample-contents-container',
    id:'sample-contents-container-001',
    ttl: 'Search&Filter Tool part1',
    item: <SearchFilterTool />
  },
  {
    // id:'sample-contents-container',
    id:'sample-contents-container-002',
    ttl: 'Search&Filter Tool part2',
    item: <SearchFilterTool2 />
  },
]
function Dev() {
  return(
    <><div className='flex'><a className='m-auto' href='https://dev2.kaedenakashima.com/python'><button className='dev-button'>Dev Click here</button></a></div></>
  )
}
function WorkSample() {
  const [selectedItem, setSelectedItem] = useState('Linear Generator')
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const maxValue = workSamples.length
  const [sampleNum, setSampleNum] = useState(0) 
  const [move, setMove] = useState(false)
  const [left, setLeft] = useState(0) 
  const [initialDiff, setInitialDiff] = useState(0)
  const [initialPoint, setInitialPoint] = useState(0)
  const [endPoint, setEndPoint] = useState(0)
  const onClick2Next = (e)=>{
    if(sampleNum < (workSamples.length-1)){
      setSampleNum(sampleNum + 1) 
    } else { setSampleNum(0) }
  }
  const onClick2MoveStart = (e)=>{
    setMove(true)
    setInitialDiff(e.clientX-left)
    setInitialPoint(e.clientX)
  }
  const onClick2Move = (e)=>{
    if(move){
      setLeft(e.clientX-initialDiff) 
    }
  }  
  const onClick2MoveEnd = (e)=>{
    if(move){
      if((e.clientX-initialPoint) > 15){
        if(sampleNum < (workSamples.length-1)){
         setSampleNum(sampleNum + 1) 
         setLeft(0)
         console.log('slide to next slide')
        }else{
          setSampleNum(0)
          setLeft(0)
        }
      } else if ( -15 > (e.clientX-initialPoint)){
        if(sampleNum !== 0){
        setSampleNum(sampleNum - 1)
        setLeft(0)
        }else{
          setSampleNum(workSamples.length-1)
          setLeft(0)
        }
      }
      setMove(false)

    }
  } 
  const touchstart2 = (e)=>{
    setMove(true)
    setInitialDiff(e.touches[0].clientX-left)
    setInitialPoint(e.touches[0].clientX)
  }  
  const touchmove2 = (e)=>{
    if(move){
      setLeft(e.touches[0].clientX-initialDiff) 
    }
  } 
  const touchend2 = (e)=>{
    if(move){
      if((e.changedTouches[0].clientX -initialPoint) > 15){
        if(sampleNum < (workSamples.length-1)){
         setSampleNum(sampleNum + 1) 
         setLeft(0)
         console.log('slide to next slide')
        }else{
          setSampleNum(0)
          setLeft(0)
        }
      } else if ( -15 > (e.changedTouches[0].clientX -initialPoint)){
        if(sampleNum !== 0){
        setSampleNum(sampleNum - 1)
        setLeft(0)
        console.log('slide to prev slide')
        }else{
          setSampleNum(workSamples.length-1)
          setLeft(0)
        }
      }
      setMove(false)

    }
  }   
  return (
    <>
      <Container maxWidth="md">
        <SectionTitle title='Work Samples' />
      </Container>
      <Container maxWidth="lg">
        <div className='pc-worksample'>
          <div className='vertical-nav'>
            {workSamples.map((x, i) => (<button
              key={i}
              onClick={() => setSelectedItem(x.ttl)}
              className={selectedItem === x.ttl ? 'nav-item-ttl-active' : undefined}
              style={{
                textAlign: 'center', textDecoration: 'none', color: '#D9D9D9',
                fontWeight: '400', cursor: 'default', border: 'none', margin: '2px 0',
                backgroundColor: 'transparent', fontFamily: 'raleway', fontSize: '12px'
              }}
            >
              <VscCircleLargeFilled />
            </button>
            ))}
          </div>
          <div className='workSample-content'>
            {workSamples.map((x, i) => x.ttl === selectedItem && <div key={i}>{x.item}</div>)}
          </div>
        </div>
      </Container>
      <div className='mobile-worksample-container' maxWidth="xl">
        <div className='mobile-comment flex' onClick={onClick2Next}>Swipe to see next<BiChevronsRight className='right-arrow-icon' /></div>
      <div className='mobile-tmp-box-container'>
      <div 
          onMouseDown={onClick2MoveStart}
          onMouseMove={onClick2Move}
          onMouseUp={onClick2MoveEnd}
          onTouchStart={touchstart2}
          onTouchMove={touchmove2}
          onTouchEnd={touchend2}
          style={{
          left:Number(`${left}`),
          width: '100%',
          height: '500px',
          position: 'absolute',
          }}
          className='mobile-tmp-box'
        >
          {workSamples[Number(`${sampleNum}`)].item}
        </div>
        </div>
      </div>
    </>
  )
}
function Profile() {
  return (
    <>
      <Container maxWidth="md">
        <SectionTitle title='Profile' className='first-section' />
        <div className='profile-container'>
          <Box>
            <span className='tab-space'> </span>I’ve been working as a web developer worldwide more than 8 years. I had worked in Berlin, Germany and Sydney, Australia.
            I’ve been working as a web developer worldwide more than 8 years. I had worked in Berlin, Germany and Sydney, Australia.
            <br />
            <span className='tab-space'> </span>Right now, I am developing finance web application with React at company. I apply modern web technologies into new application to help user to process payment to be manageable and easy to use. If you are not familiar with React, React is one of the popular open resource library to create modern applications invented by Facebook. React helps developers to create functional web sites dynamically. Some of well known company such as Netflix, Shopify and Uber are using react for their web services.
            <br />
            <span className='tab-space'> </span>At university, I learned aesthetic and that leads me to choose more sophisticated and sustainable designs. As a software engineer, I think how much programming and technology you can use at web pages is a key. I also think how we can improve web pages to be cleaner and easier to use is important for users.
            <br /><br />
            Please feel free to contact me if you are interested to work with me.
            <br />
          </Box>
        </div>
      </Container>
      <Container maxWidth="md" className='background-img-tech'>
        <SectionTitle title='Development Skills' />
        <p>Here are tools and open source libraries I use for creating modern websites.<br />
          <div className='m-4 dev-skills'>
            ・Implement design to code.<br />
            ・Construct responsive web page with different devices.
          </div>
          Skills:<br />
          <div className='skill-list-container'>
            <div>
              <b>Frontend</b>
              <ul>
                <li>Javascript</li>
                <li>TypeScript</li>
                <li>Gatsby</li>
                <li>Graph QL</li>
                <li>Apollo</li>
                <li>Redux</li>
                <li>MDX</li>
              </ul>
            </div>
            <div>
              <b>Backtend</b>
              <ul>
                <li>Node.js</li>
                <li>Python</li>
              </ul>
            </div>
            <div>
              <b>UI</b>
              <ul>
                <li>Material</li>
                <li>Rebass</li>
                <li>react-spring<br />(For animation)</li>
                <li>Algoria<br />(For search engine)</li>
              </ul>
            </div>
            <div>
              <b>API Database</b>
              <ul>
                <li>MongoDB</li>
                <li>Firebase</li>
                <li>PostgresSQL</li>
                <li>Postman</li>
              </ul>
              <b>API Testing</b>
              <ul>
                <li>Jest</li>
              </ul>
            </div>
          </div>
        </p>
      </Container>
    </>
  )
}
function Contact() {
  return (
    <>
      <Container maxWidth="md">
        <SectionTitle title='Contact' />
        <div className='contact-container'>
          <form action='https://formspree.io/f/maypalap' method='POST'>
            <div className='contact-input-container'>
              <input
                type='email'
                name='email'
                placeholder='email'
                className='contact-email'
              />
              <textarea
                name='message'
                rows='5'
                placeholder='message'
                className='contact-message'
              >
              </textarea>
              <div className='contact-submit-container'>
                <button type='submit' className='contact-submit'>Send</button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </>
  )
}
render(<App />, document.getElementById("root"))