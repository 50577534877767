import React from 'react'
import '../scss/index.css'
import { Link } from "react-router-dom";

import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import { GiBookCover } from 'react-icons/gi'
import { RiFilePaper2Fill } from 'react-icons/ri'

const hero = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: { xs: 'column' },
        bgcolor: '#fff',
        borderRadius: 2,
        p: 2,
        width: 600,
        maxWidth: { xs: 350, md: 500 },
      }}
      className='banner-title'
    >
      <Typography><h1>Portfolio<span>Kaede Nakashima</span></h1></Typography>
      <p>Hello. I'm Kaede Nakashima.<br />A software・AI engineer who mainly use react for development.</p>
    </Box>
  )
}

export default hero