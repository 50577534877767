import React, { useState, useEffect} from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import styled from 'styled-components'
import './style.css'
import axios from 'axios'
const APIURL2 = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_DB_ENV2}` : `${process.env.REACT_APP_DB_ENV_TEST2}`
var defaultLinearGraphData = [
    {
        tableID: 1,
        title: 'Apple Inc',
        apiStockName: 'AAPL',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/AAPL:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 2,
        title: 'Amazon.com Inc',
        apiStockName: 'AMZN',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/AMZN:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 3,
        title: 'Alphabet Inc Class A',
        apiStockName: 'GOOGL',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/GOOGL:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 4,
        title: 'Meta Platforms Inc',
        apiStockName: 'META',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/META:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 5,
        title: 'NVIDIA Corp',
        apiStockName: 'NVDA',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/NVDA:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 6,
        title: 'Microsoft Corp',
        apiStockName: 'MSFT',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/MSFT:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    },
    {
        tableID: 7,
        title: 'Tesla Inc',
        apiStockName: 'TSLA',
        resourceTitle: 'Google Finance',
        url: 'https://www.google.com/finance/quote/TSLA:NASDAQ?hl=en',
        updateddate: new Date().toLocaleDateString("en-US"),
        items20: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6M: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items1Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items3Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }],
        items6Y: [{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 },{ name: '', value: 0 }]
    }
]

const StyledContainer = styled.div`
  padding: 0 20px;
  font-size: 12px;
`
const currency = (tableID) => {
    return "US$"
}
const HoverLabel = ({ active, payload, tableID, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="hover-label">
                {`${label} ${currency(tableID)}${payload[0].value}`}
            </div>
        )
    }
    return null
}
export default function LinearGenerator() {
    const [selectedData, setSelectedData] = useState('3M')
    const [selectedApiStockName1, setSelectedApiStockName1] = useState(defaultLinearGraphData[0].apiStockName)
    const [linearGraphData, setLinearGraphData] = useState(defaultLinearGraphData)
    const [selectedApiStockItemIndex1, setSelectedApiStockIndex1] = useState(0)
    const [selectedStock, setSelectedStock] = useState(defaultLinearGraphData[0])
    const [maxRange, setMaxRange] = useState(500)
    const term = [{ttl: '3M'},{ttl: '6M'},{ttl: '1Y'},{ttl: '3Y'},{ttl: '6Y'}]
    useEffect(() => {
        getStock()
    },[])
    const getStock = async () => {
        try{
            const { data: res } = await axios.get(`${APIURL2}/stock`)
            const data1 = res.nasdaqstocks
            const data2 = res.nasdaqstockvalues
            var M3 = ['m3', 'm2_5', 'm2', 'm1_5', 'm1', 'm0_5', 'm0']
            var M6 = ['m6', 'm5', 'm4', 'm3', 'm2', 'm1', 'm0']
            var Y1 = ['y1', 'm10', 'm8', 'm6', 'm4', 'm2', 'm0']
            var Y3 = ['y3', 'y2_5', 'y2', 'y1_5', 'y1', 'm6', 'm0']
            var Y6 = ['y6', 'y5', 'y4', 'y3', 'y2', 'y1', 'm0']
            function trimItem1(x1){
                var formatted = []
                var year = []
                for (let x in x1) {
                    var findYear = [...x1[x].name].slice(0, 4).join('')
                    if(!year.includes(findYear)){
                        formatted.push(x1[x])
                        year.push(findYear)
                    }else{
                        formatted.push({
                            ...x1[x],
                            name: x1[x].name.slice(5, 10)
                        })
                    }
                }
                return formatted
            }
            function trimItem2(x1){
                var formatted = []
                var year = []
                var mmdd = []
                for (let x in x1) {
                    var findYear = [...x1[x].name].slice(0, 4).join('')
                    var mmddFormat = [...x1[x].name].slice(5, 10).join('')
                    if(!year.includes(findYear)){
                        formatted.push(x1[x])
                        year.push(findYear)
                    }else{
                        if(!mmdd.includes(mmddFormat)){
                            formatted.push(x1[x])
                        }else{
                            formatted.push({
                                ...x1[x],
                                name: mmddFormat
                            })
                            mmdd.push(mmddFormat)
                        }
                    }
                }
                return formatted
            }
            for(let i = 0; i <= 6; i++){   
                const stockData =data2[data1[i].apistockname]
                data1[i]['items20'] = data2[data1[i].apistockname]
                data1[i]['items3M'] = trimItem1(stockData.filter(function(x){if(M3.includes(x.itemterm)){return x}}))
                data1[i]['items6M'] = trimItem1(stockData.filter(function(x){if(M6.includes(x.itemterm)){return x}}))
                data1[i]['items1Y'] = trimItem1(stockData.filter(function(x){if(Y1.includes(x.itemterm)){return x}}))
                data1[i]['items3Y'] = trimItem2(stockData.filter(function(x){if(Y3.includes(x.itemterm)){return x}}))
                data1[i]['items6Y'] = trimItem1(stockData.filter(function(x){if(Y6.includes(x.itemterm)){return x}}))
            }
            setLinearGraphData(data1)
            setSelectedStock(data1[0])
        }catch(err){console.log(err)}
    }

    const onClick2Term = (e, ttl) => {
        e.preventDefault()
        setSelectedData(ttl)
    }
    const onChange2StockName = async (e) => {
        await setSelectedApiStockIndex1(e.target.value - 1)
        await setSelectedStock(linearGraphData[e.target.value - 1])
        await setSelectedApiStockName1(linearGraphData[e.target.value - 1].apistockname)
    }
    return (
        <StyledContainer>
            <h4 className='sample-title'>Linear Generator</h4>
            <div className='tag-container'>
                <span className='tech-tag'>React</span><span className='tech-tag'>Python</span><span className='tech-tag'>Recharts</span><span className='tech-tag'>Rapid API</span><span className='tech-tag'>Mongo DB</span>
            </div>
            <div className='sample-contents-container-006'>
                <div className='sample-title-container-006'>
                    <FormControl className='select-field' sx={{ m: 1, mt: 3 }} lg={{ m: 1, mt: 3 }}>
                        Select Nasaq stock
                        <Select
                            defaultValue={1}
                            onChange={onChange2StockName}
                            input={<OutlinedInput />}
                            className="table-title-container"
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {linearGraphData.map((x) => (
                                <MenuItem value={x.tableID}>{x.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div>
                        Data Resource : {selectedStock.resourceTitle}<br />
                        <a href={selectedStock.url}>▶︎ Click here to see the original data</a>
                    </div>
                    Rate on {linearGraphData[selectedApiStockItemIndex1].items3M[6].name}:<h2>US${linearGraphData[selectedApiStockItemIndex1].items3M[6].value} {selectedStock[`items${selectedData}`].map(x=>x.value)[6]/selectedStock[`items${selectedData}`].map(x=>x.value)[0]-1>0?<span className='ml-1' style={{color:'#00B655'}}>+{Math.round((selectedStock[`items${selectedData}`].map(x=>x.value)[6]/selectedStock[`items${selectedData}`].map(x=>x.value)[0]-1)*100)}%</span>:<span c className='ml-1' style={{color:'red'}}>-{Math.round((1-(selectedStock[`items${selectedData}`].map(x=>x.value)[6]/selectedStock[`items${selectedData}`].map(x=>x.value)[0]))*100)}%</span>}</h2>
                    {/* <button onClick={onClick2UpdateAPI} className='update-btn' disabled={!(stockUpdateRequired)}>Update Yahoo Finace API data(takes 30s)</button> */}
                </div>
                <div className='sample-table-container-006'>
                    <h4>Data Result :</h4>
                    <div className='rate-tab'>{term.map((x, i) => (<button key={i} onClick={(e) => onClick2Term(e, x.ttl)} className={selectedData === x.ttl ? 'term-ttl-active' : undefined}
                >{x.ttl}</button>
                ))}</div>
                    <div className='graph-container'>
                    {term.map((x, i) => x.ttl === selectedData && <div key={i}>{x.data}</div>)}
                        <LineChart className='line-graph-pc' width={500} height={200}>
                        <CartesianGrid vertical={false} stroke="#f5f5f5" />
                        <XAxis dataKey="name" type="category" allowDuplicatedCategory={false} />
                        <YAxis dataKey="value" type="number" tickCount="9" 
                        allowDataOverflow domain={[ 0, Math.round(Math.max(...selectedStock[`items${selectedData}`].map(x=>Number(x.value))))+100]}
                        />
                        <Tooltip content={<HoverLabel />}/>
                        <Line
                            dataKey="value"
                            data={selectedStock[`items${selectedData}`]}
                            type="linear"
                            stroke="#000"
                        />
                        </LineChart>
                        <ResponsiveContainer width="100%" height="100%">
                        <LineChart className='line-graph-sp'
                        width={250}
                        height={100}
                        >
                        <CartesianGrid vertical={false} stroke="#f5f5f5" />
                        <XAxis dataKey="name" type="category" allowDuplicatedCategory={false} />
                        <YAxis dataKey="value" type="number" allowDataOverflow tickCount="9" domain={[ 0, Math.round(Math.max(...selectedStock[`items${selectedData}`].map(x=>Number(x.value))))+100]}/>
                        <Tooltip content={<HoverLabel />}/>
                        <Line dataKey="value" data={selectedStock[`items${selectedData}`]}
                            type="linear"
                            stroke="#000"
                        />
                        </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </StyledContainer >
    )
}
